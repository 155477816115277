<template>
  <BCard>
    <div class="text-black text-2xl font-semibold mb-1">
      Daftar Talent
    </div>
    <BTabs
      class="mt-2"
      content-class="mt-1"
      pills
      active-nav-item-class="rounded-pill"
      v-model="tabIndex"
    >
      <BTab
        :active="$route.query.tab === 'registered'"
        lazy
        title-item-class="bg-[#F8F8F8] mr-[24px] rounded-[25px]"
      >
        <template #title>
          {{`Registered (${dataTotal.total_registered || 0})`}}
        </template>
        <Registered />
      </BTab>
      <BTab
        title="Selected"
        :active="$route.query.tab === 'selected'"
        lazy
        title-item-class="bg-[#F8F8F8] mr-[24px] rounded-[25px]"
      >
        <template #title>
          {{`Selected (${dataTotal.total_selected || 0})`}}
        </template>
        <Selected />
      </BTab>
      <BTab
        title="Hired"
        :active="$route.query.tab === 'hired'"
        lazy
        title-item-class="bg-[#F8F8F8] mr-[24px] rounded-[25px]"
      >
        <template #title>
          {{`Hired (${dataTotal.total_hired || 0})`}}
        </template>
        <Hired />
      </BTab>
      <BTab
        title="Multiple"
        :active="$route.query.tab === 'multiple'"
        lazy
        title-item-class="bg-[#F8F8F8] mr-[24px] rounded-[25px]"
      >
        <template #title>
          {{`Multiple (${dataTotal.total_multiple || 0})`}}
        </template>
        <Multiple />
      </BTab>
      <BTab
        title="Non Job"
        :active="$route.query.tab === 'non-job'"
        lazy
        title-item-class="bg-[#F8F8F8] mr-[24px] rounded-[25px]"
      >
        <template #title>
          {{`Non Job (${dataTotal.total_nonjob || 0})`}}
        </template>
        <NonJob />
      </BTab>
      <BTab
        title="Talent Off"
        :active="$route.query.tab === 'talent-off'"
        lazy
        title-item-class="bg-[#F8F8F8] mr-[24px] rounded-[25px]"
      >
        <template #title>
          {{`Talent Off (${dataTotal.total_off || 0})`}}
        </template>
        <TalentOff />
      </BTab>
    </BTabs>
  </BCard>
</template>

<script>
import { komtimAxiosIns } from '@/libs/axios'
import Registered from './status/Registered.vue'
import Selected from './status/Selected.vue'
import Hired from './status/Hired.vue'
import Multiple from './status/Multiple.vue'
import NonJob from './status/Non-job.vue'
import TalentOff from './status/Talent-off.vue'

export default {
  components: {
    Registered,
    Selected,
    Hired,
    Multiple,
    NonJob,
    TalentOff,
  },
  data() {
    return {
      tabIndex: 0,
      tabs: ['registered', 'selected', 'hired', 'multiple', 'non-job', 'talent-off'],
      dataTotal: {},
    }
  },
  mounted() {
    this.getTotal()

    this.$root.$on('bv::dropdown::show', bvEvent => {
      if (bvEvent.componentId === 'dropdown-2' || bvEvent.componentId === 'dropdown-3' || bvEvent.componentId === 'dropdown-4') {
        this.isDropdown2Visible = true
      }
    })
    this.$root.$on('bv::dropdown::hide', bvEvent => {
      if (bvEvent.componentId === 'dropdown-2' || bvEvent.componentId === 'dropdown-3' || bvEvent.componentId === 'dropdown-4') {
        this.isDropdown2Visible = false
      }
      if (this.isDropdown2Visible) {
        bvEvent.preventDefault()
      }
    })

    const tab = this.tabs[0]
    this.$router.replace({ query: { tab } }).catch(err => {})
  },
  watch: {
    tabIndex(newTab, oldtab) {
      const tab = this.tabs[newTab]
      this.$router.replace({ query: { tab } }).catch(err => {})

      this.getTotal()
    },
  },
  methods: {
    getTotal() {
      const url = 'v1/talents/total'
      komtimAxiosIns.get(url)
        .then(res => {
          const { data } = res.data
          this.dataTotal = data
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          this.$toast_error({ message: 'get data product option failed' })
        })
    },
  },
}
</script>
<style scoped lang="scss">
@import './style/Biodata.scss';
</style>
